import { gql } from "graphql-request";
import { useQuery } from 'react-query';
import { client } from "graphQl/client";
import { queryCpt, queryCptAcf } from "graphQl/queries";
import { capitalizeFirstLetter } from "utilities/capitalizeFirstLetter";

/*
  postType: string
  order: string
  orderBy: string
*/

const useCpt = ({ 
  postType, 
  order = 'DESC', 
  orderBy = 'DATE', 
}) => {
  return useQuery(`CPT_${postType.toUpperCase()}`, async () => {
    const data = await client.request(
      gql`
        query get${capitalizeFirstLetter(postType)}s {
          cpt${capitalizeFirstLetter(postType)}s(last: 1000, where: {orderby: {order: ${order}, field: ${orderBy}}}) {
            nodes {
              ${queryCpt}
              ${queryCptAcf[postType]}
            }
          }
        }
      `
    )
    
    return data;
  });
};

export default useCpt;
