import { SectionContext } from 'context/SectionContext';
import React, { useContext, useEffect, useState } from 'react';
import { renderText } from './texts';

const AlertBar = () => {
  const [ isActive, setIsActive ] = useState(false);
  const [ currentText, setCurrentText ] = useState(0);
	const { modalVimeoIsOpen, openSliderStack } = useContext(SectionContext);


  const inactivityTime = () => {
    let time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    
    function logout() {
      setIsActive(true);
    }
    
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 20000)
    }
  };
  
  useEffect(() => {
    setIsActive(false);
    inactivityTime();
  }, [modalVimeoIsOpen, openSliderStack])

  const closeAlertBar = () => {
    setIsActive(false);

    setTimeout(() => {
      const randomNumber = Math.floor(Math.random() * 6);
      setCurrentText(randomNumber);
    }, 500);
  }

  return (
    <div className={`alert-bar ${isActive && !modalVimeoIsOpen && !openSliderStack ? 'active' : ''}`}>
      <div className='alert-bar__container container'>
        <div className='alert-bar__bell-container'>
          <img 
            className='alert-bar__bell'
            src={`/images/icons/bell.png`} 
            alt='Close Alert' 
          />
        </div>
        <div className='alert-bar__text-container'>
          {renderText(currentText, closeAlertBar)}
          <img 
            className='alert-bar__close'
            src={`/images/icons/close-transparent.svg`} 
            alt='Close Alert' 
            onClick={closeAlertBar}
          />
        </div>
      </div>
    </div>
  )
}

export default AlertBar;