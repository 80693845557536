import React from 'react';

const FormMessage = ({success}) => {
  return (
    <div className={`form-message form-message--${success ? 'success' : 'error'}`}>
      <div className='form-message__container'>
        <img className='form-message__icon' src={`${process.env.PUBLIC_URL}/images/icons/form-message--${success ? 'success' : 'error'}.svg`} alt='Success Icon' />
        <h3 className='form-message__title'>{success ? 'Hooray!' : 'Uh-Oh...'}</h3>
      </div>
      <div className='form-message__copy'>{success ? 'Your message was sent!' : 'Something went wrong'}</div>
    </div>
  )
}

export default FormMessage;