import React, { useContext, useEffect, useRef, useState } from 'react';
import { SectionContext } from 'context/SectionContext';

const Timer = ({resetGame}) => {
	const { timer } = useContext(SectionContext);
  const [seconds, setSeconds] = useState(timer.seconds);
  const [minutes, setMinutes] = useState(timer.minutes);
  let interval = useRef();

  const updateCountDown = () => {
    // Count Finished
    if (seconds === 0 && minutes === 0) {
      resetGame();
      return;
    }

    // Count Start
    interval = setInterval(() => {
      setSeconds(seconds - 1);
  
      if (seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(60);
      }
    }, 1000)
  }

  useEffect(() => {
    updateCountDown();
    return () => {
      clearInterval(interval);
    }
  })

  // Reset Timer
  useEffect(() => {
    const resetTimer = () => {
      setSeconds(timer.seconds)
      setMinutes(timer.minutes)
    }
    resetTimer()
  }, [timer])

  return (
    <div className='puzzle__timer-container'>
      <div className='puzzle__timer'>
        <img className='puzzle__timber-img' src={process.env.PUBLIC_URL + '/images/icons/timer.svg'} alt='Count Down' />
        <p className='puzzle__timer-count'>
          {minutes}
          :
          {seconds > 9 ? seconds : '0' + seconds}</p>
      </div>
    </div>
  )
}

export default Timer;