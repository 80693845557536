import { React, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import pageData from 'data/page-data';
import { SectionContext } from 'context/SectionContext';

const Slider = () => {

	const history = useHistory();
	const { setShowSlider, showSlider, pageId } = useContext(SectionContext);
	// Pages filtered without Just Ask page
	const pages = pageData[0].pages.filter(({path}) => path !== '/just-ask');

	// Get next page's info
	const { title, characterChibi, className, path: pathNext } = pages[pageId + 1] ? pages[pageId + 1] : pages[0];
	const { slider1 = '', gradient2 = '' } = pageData[0].pages[pageId]?.colors;
	
	// Get previous page's path
	const { path: pathPrevious } = pages[pageId - 1] ? pages[pageId - 1] : pages[pages.length - 1];

	const handleClick = (e) => {
		e.preventDefault();
		setShowSlider(!showSlider)
		history.push(e.target.value);
	}

	// Create a clickable "dot" for every page
	const dots = pages.map(({id, path}) => {
		return (
			<NavLink 
				key={id} 
				to={path} 
				onClick={() => setShowSlider(!showSlider)} 
				className="slider__option" 
				activeClassName="slider__option--active"
			></NavLink>
		)
	});

	return(
		<div className="slider">

			<div className="slider__options">
				<button className="slider__arrow" value={pathPrevious} onClick={handleClick}>&#10094;</button>
				{dots}
				<button className="slider__arrow" value={pathNext} onClick={handleClick}>&#10095;</button>
			</div>

			<CSSTransition 
				in={showSlider}
				timeout={{ appear: 500, enter: 1000, exit: 500 }}
				classNames={'slider__slide-container-'}
				appear={true}
				enter={true}
				unmountOnExit
				onExited={() => setShowSlider(!showSlider)}
			>
				<NavLink to={pathNext} className='slider__slide-link'>
					<div className={`slider__slide-container ${className}`} style={{backgroundColor: `${gradient2}`}}>
						<div className="slider__character-container" style={ { backgroundColor: `${slider1 ? slider1 : ''}` } }>
							<img className="slider__character" src={ process.env.PUBLIC_URL + characterChibi } alt=""/>
						</div>
							<span className="slider__title">{ title.toUpperCase() }</span>
					</div>
				</NavLink>
			</CSSTransition>

		</div>
	)
}

export default Slider;