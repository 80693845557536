import React, { useContext, useState } from 'react';
import pageData from 'data/page-data';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Button, Slider, Form, Character, Mute, SocialMedia } from 'components';
import { SectionContext } from 'context/SectionContext';
import { Howl } from 'howler';
import ctaSound from 'assets/sounds/buttonclick.mp3';
import { useHistory } from 'react-router';

const Hero = () => {
	const history = useHistory();
	const { sectionDetails, setSectionDetails, pageId, isMute } = useContext(SectionContext);
	const { title, ghost_title, copy, buttonText, className } = pageData[0].pages[pageId];
	
  const ctaHover = new Howl({
		src: [ctaSound],
		volume: 0.3,
		mute: isMute,
	});

	const handleMainButton = (e) => {
    e.preventDefault();
    if (pageId === 5) {
      window.open("https://www.youtube.com/channel/UCtTbDE5ZqnAlL61ZHakFGng");
    } else {
      setSectionDetails(true)
    }

    ctaHover.play()
	}

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
	const pagePaths = pageData[0].pages.filter(({path}) => path !== '/just-ask');

  const handleTouchStart = ({ targetTouches }) => {
    setTouchStart(targetTouches[0].clientX);
  }

  const handleTouchMove = ({ targetTouches }) => {
    setTouchEnd(targetTouches[0].clientX);
  }

  const handleTouchEnd = () => {
    setTouchStart(0);
    setTouchEnd(0);
    if (touchEnd !== 0) {
      if (touchStart - touchEnd > 150) {
        const nextPath = pageId === (pagePaths.length - 1) ? pagePaths[0].path : pagePaths[pageId + 1].path;
        history.push(nextPath);
      }
  
      if (touchStart - touchEnd < -150) {
        const prevPath = pageId === 0 ? pagePaths[pagePaths.length - 1].path : pagePaths[pageId - 1].path;
        history.push(prevPath);
      }
    }
  }

  return (
    <div 
      className="hero inner-container"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >

     {!sectionDetails && <TransitionGroup>
        <CSSTransition
          key={ghost_title}
          timeout={1000}
          classNames="hero__ghost-title-"
          unmountOnExit={true}
        >
          <span className="hero__ghost-title">{ghost_title}</span>
        </CSSTransition>
      </TransitionGroup>}

      {!sectionDetails && <TransitionGroup>
        <CSSTransition
          key={title}
          timeout={1500}
          classNames="hero__info-"
          unmountOnExit={true}
        >
          <div className="hero__info">
            <h1 className="hero__title">{title}</h1>
            {copy && <p className="hero__copy paragraph-l">{copy}</p>}
            {buttonText && 
              <Button
                className="hero__button button--white" 
                title={buttonText} 
                iconUrl="/images/icons/arrow-right.svg" 
                onClick={handleMainButton}
              />
            }
          </div>
        </CSSTransition>
      </TransitionGroup>}
      
      {/* Title and ghost for the expanded content */}
      {sectionDetails && 
        <div className="hero__info expanded">
          <span className="hero__ghost-title">{ghost_title}</span>
          <h1 className="hero__title">{title}</h1>
        </div>
      }

      {/* Character */}
      <Character />
     
      {/* Animates the Form */}
      <TransitionGroup className='hero__form-container'>
        <CSSTransition
          key={pageId}
          timeout={{enter: 10000, exit: 10000}}
          classNames={`form--${className} form-`}
        >
          <Form />
        </CSSTransition>
      </TransitionGroup>

      <SocialMedia />
      <Mute />
      <Slider />
    </div>
  )
}

export default Hero;