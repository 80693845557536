import React from 'react';

export const SlickArrowNext = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "custom-slick-arrows slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <img className="slick-arrows slick-arrows--next" src={process.env.PUBLIC_URL + '/images/icons/arrow.svg'} alt="next arrow" />
  </button>
);

export const SlickArrowPrev = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "custom-slick-arrows slick-prev slick-arrow" +
      (currentSlide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <img className="slick-arrows slick-arrows--prev" src={process.env.PUBLIC_URL + '/images/icons/arrow.svg'} alt="next arrow" />
  </button>
);