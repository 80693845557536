import React from 'react';
import { usePagination } from '../../hooks/usePagination';
import { Video, Pagination, SectionHeader } from 'components';
import Slider from 'react-slick';

const VideosGrid = ({ title, description, videos, showBubbles, videosForPage = 9, themeColor = 'violet', videoTitleColor = 'violet', showGridOnMobile = true }) => {
  const {paginatedItems, setPage, page, itemsForPage} = usePagination({
    initPage: 0,
    itemsForPage: videosForPage,
    items: videos,
  })

  const settingsSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots custom-slick-dots custom-slick-dots--' + themeColor,
  };
  
  return (
    <section className={`videos-grid videos-grid--${themeColor}`} >
      {showBubbles && <img className='videos-grid__background' src={`${process.env.PUBLIC_URL}/images/icons/background/videos-grid.png`} alt='Background' />}

      <div className='container'>
        {/* Header */}
        <SectionHeader
          title={title}
          description={description}
        />

        {/* Grid */}
        <div className={`videos-grid__grid ${!showGridOnMobile ? 'hide-on-mobile' : ''}`}>
          {
            paginatedItems.map(({ databaseId, title, acf: {cover, idVideo, timer} }) =>  {
              return (
                <Video 
                  key={databaseId} 
                  playColor={themeColor} 
                  titleColor={videoTitleColor} 
                  title={title}
                  idVideo={idVideo}
                  timer={timer}
                  cover={cover}
                />
              )
            })
          }
        </div>

        {/* Slider */}
        <div className={`videos-grid__slider ${!showGridOnMobile ? 'show-on-mobile' : ''}`}>
          <Slider {...settingsSlider}>
             {
              paginatedItems.map(({ databaseId, title, acf: {cover, idVideo, timer} }) =>  {
                return (
                  <Video 
                    key={databaseId} 
                    playColor={themeColor} 
                    titleColor={videoTitleColor} 
                    title={title}
                    idVideo={idVideo}
                    timer={timer}
                    cover={cover}
                  />
                )
              })
            }
          </Slider>
        </div>

        {/* Pagination */}
        <Pagination 
          page={page}
          items={videos}
          itemsForPage={itemsForPage}
          setPage={setPage}
          themeColor={themeColor}
          showOnMobile={showGridOnMobile}
        />
      </div>
    </section>
  )
}

export default VideosGrid;