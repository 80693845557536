import React, { useContext } from 'react';
import { SectionContext } from 'context/SectionContext';
import { Image } from 'components';

const Video = ({title, cover, timer, idVideo, icon, titleColor = 'violet', playColor = 'violet'}) => {
	const { setIdVimeo, setModalVimeoIsOpen } = useContext(SectionContext);
  
  const handleOpenModalVimeo = () => {
    setIdVimeo(idVideo)
    setModalVimeoIsOpen(true);
  }

  return (
    <div className={`video  ${icon ? 'video--with-icon' : ''}`} onClick={handleOpenModalVimeo}>
      <div className={`video__content ${cover ? '' : 'placeholder'}`}>
        {cover && 
          <Image
            className='video__placeholder'
            src={cover.sourceUrl}
            alt={cover.altText ? cover.altText : title} 
          />
        }
        <img className='video__icon' src={`${process.env.PUBLIC_URL}/images/icons/video-play--${playColor}.svg`} alt='Play' />
        {timer &&
          <p className='video__timer'>{timer}</p>
        }
      </div>
      <div className={`video__title-container`}>
        {icon && <img className='video__title-icon' src={`${process.env.PUBLIC_URL}/images/icons/${icon}.svg`} alt={icon} />}
        <p className={`video__title video__title--${titleColor}`}>{title}</p>
      </div>
    </div>
  )
}

export default Video;