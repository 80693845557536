import React from 'react';

const Pagination = ({page, items, itemsForPage, setPage, themeColor = 'violet', showOnMobile = true}) => {
  const pages = Math.ceil(items.length / itemsForPage);

  return (
    (
      pages !== 1 && (
        <div className={`pagination pagination--${themeColor} ${!showOnMobile ? 'hide-on-mobile': ''}`} >
          {
            [...Array(pages)].map((x, i) => {
              return (
                <div 
                  key={i} 
                  className={`pagination__index ${i === page ? 'active' : ''}`} 
                  onClick={() => setPage(i)}
                >
                  <p>{i+1}</p>
                </div>
              )
            })
          }
          {
            page + 1 < pages && 
              <img 
                src={process.env.PUBLIC_URL + '/images/icons/next.svg'} 
                alt='Next Page' 
                onClick={() => setPage(page + 1)}
              />
          }
        </div>
      )
    )
  )
}

export default Pagination;
