import React from 'react';

const StepCard = ({description, icon, index}) => {
  return (
    <div className='step-card'>
      <div className='step-card__index'>{index + 1}</div>
      <div className='step-card__icon-container'>
        <img className='step-card__icon' src={process.env.PUBLIC_URL + icon} alt='Step Icon' />
      </div>
      <p className='step-card__description description' dangerouslySetInnerHTML={{__html: description}} />
    </div>
  )
}

export default StepCard;