import React from 'react';
import { Title, GameCard } from 'components';

const GamesGrid = ({ games, title }) => {
  return (
    <section className='games-grid'>
      <div className='games-grid__container container'>
      {title &&
        <Title className='steps__title'>{title}</Title>
      }
        <div className='games-grid__grid'>
          {
            games.map(({ databaseId, title, acf:{ description, image, vimeoUrl }, game, isHtmlGame }) => {
              return (
                <GameCard
                  key={databaseId} 
                  title={title}
                  description={description}
                  thumbnail={image}
                  idVideo={vimeoUrl}
                  game={game}
                  isHtmlGame={isHtmlGame}
                />
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default GamesGrid;