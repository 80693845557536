import { React, useState } from 'react';
import { Router } from 'components';
import { SectionContext } from 'context/SectionContext';
import { usePageViews } from 'hooks/usePageViews';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'graphQl/client';

const App = () => {

  // Set Google Analytics
  usePageViews();

  const [sectionDetails, setSectionDetails] = useState(false);
  const [showSlider, setShowSlider] = useState(true);
  const [showMainContainer, setShowMainContainer] = useState(false);
  const [openSliderStack, setOpenSliderStack] = useState(false);
  const [pageId, setPageId] = useState(0);
  const [video, setVideo] = useState(true);
  const [modalVimeoIsOpen, setModalVimeoIsOpen] = useState(false);
  const [idVimeo, setIdVimeo] = useState(null);
  const [menuClicked, setMenuClicked] = useState(false);
  const [itemsSliderStack, setItemsSliderStack] = useState(null);

  // if "winner" is true so Winner component will appear
  const [winner, setWinner] = useState(false);
  //This hook is for mute or unmute the sound in the page
  const [isMute, setIsMute] = useState(false);

  //This hook is for mute or unmute the sound in the games
  const [isGameMute, setIsGameMute] = useState(false);

  // Set Timer
  const [timer, setTimer] = useState({
    seconds: 59,
    minutes: 14
  });

  // Modal Game
  const [modalGameIsOpen, setModalGameIsOpen] = useState(false);
  const [gameSelected, setGameSelected] = useState();

  return (
    <div className="app" id="app">
      <QueryClientProvider client={queryClient}>
        <SectionContext.Provider value={{
          setShowMainContainer,
          showMainContainer,
          setSectionDetails,
          sectionDetails,
          setItemsSliderStack,
          itemsSliderStack,
          setOpenSliderStack,
          setModalVimeoIsOpen,
          modalVimeoIsOpen,
          openSliderStack,
          setShowSlider,
          showSlider,
          setMenuClicked,
          menuClicked,
          setPageId,
          pageId,
          setIdVimeo,
          idVimeo,
          setVideo,
          video,
          isMute,
          setIsMute,
          timer,
          setTimer,
          modalGameIsOpen,
          setModalGameIsOpen,
          gameSelected,
          setGameSelected,
          setWinner,
          winner,
          isGameMute,
          setIsGameMute,
        }}>
          <Router />
        </SectionContext.Provider>
      </QueryClientProvider>
    </div>
  )
}

export default App;
