import React from 'react';
import { Button, Image } from 'components';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const ActivityCard = ({file, title, image: {sourceUrl, altText}}) => {
  return (
    <div className='activity-card'>
      <h3 className='activity-card__title'>{title}</h3>
      <div className='activity-card__img-container'>
        <Image
					className='activity-card__img'
					src={sourceUrl}
					alt={altText ? altText : title} 
				/>
      </div>

      <a className='activity-card__cta' download href={file.guid}>
        <Button title='Download' className='button--file' icon={faDownload} />
      </a>
    </div>
  )
}

export default ActivityCard;