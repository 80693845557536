import React from 'react';
import { P } from 'components';
import { NavLink } from 'react-router-dom';

export const renderText = (index, handleClose) => {
  switch(index) {
    case 0:
      return (
        <P className='alert-bar__copy'>Hey! Are you there? Play some <NavLink to={'/games'} onClick={handleClose}>Games!</NavLink></P>
      )

    case 1:
      return (
        <P className='alert-bar__copy'>Hey! Are you there? Read some <NavLink to={'/comics'} onClick={handleClose}>Comics!</NavLink></P>
      )
      
    case 2:
      return (
        <P className='alert-bar__copy'>Check out some videos over at <NavLink to={'/pub-tv'} onClick={handleClose}>Public Health TV (PubTV)</NavLink></P>
      )

    case 3:
      return (
        <P className='alert-bar__copy'>Check out our <NavLink to={'/teaching-portal'} onClick={handleClose}>Teaching Portal!</NavLink></P>
      )
      
    case 4:
      return (
        <P className='alert-bar__copy'>Students are waiting to be heard at <NavLink to={'/voices'} onClick={handleClose}>Voices</NavLink></P>
      )

    case 5:
      return (
        <P className='alert-bar__copy'>Knock! Knock! Visit E.I.N.Stein at <a href={'https://www.youtube.com/channel/UCtTbDE5ZqnAlL61ZHakFGng'} target="_blank" onClick={handleClose} rel="noreferrer">This Just-In</a></P>
      )

    default:
      return <></>
  }
}