import React, { useContext} from 'react';
import { SectionContext } from 'context/SectionContext';
import { GameSelect } from 'components';
import Modal from 'react-modal';

const ModalGame = () => {
	const { modalGameIsOpen, setModalGameIsOpen, gameSelected, setWinner, winner } = useContext(SectionContext);
  Modal.setAppElement('#root');

  const closeModal = () => {
    setModalGameIsOpen(false);
    setWinner(false);
  }
  
  return (
    <Modal
      isOpen={modalGameIsOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      contentLabel="Example Modal"
      className={`modal modal-games modal-games--${gameSelected}`}
      overlayClassName='modal-games__overlay'
    >
      <button className='modal__close' onClick={closeModal}><img src={process.env.PUBLIC_URL + '/images/icons/close-modal.svg'} alt='Close Modal' /></button>
      <GameSelect 
        gameSelected={gameSelected} 
        setWinner={setWinner} 
        winner={winner} 
      />
    </Modal>
  )
}

export default ModalGame;