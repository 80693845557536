import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSocialMedia } from 'utilities/data';
import { DonateButton } from 'components';

const SocialMedia = () => {
	const socialMediaData = getSocialMedia();

	return (
		<div className="social-media">
			<DonateButton />
			<ul className="social-media__list">
				{socialMediaData.map(({ id, url, name, icon }) => {
					return(
						<li key={id} className="social-media__item">
							<a href={url} aria-label={name} target="_blank" rel="noreferrer">
								<FontAwesomeIcon className="social-media__icon" icon={icon} />
							</a>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default SocialMedia;