import React, { useContext } from 'react';
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { SectionContext } from 'context/SectionContext';
import { Button } from 'components';

const GameCard = ({ title, description, thumbnail: {sourceUrl}, idVideo, game, isHtmlGame }) => {
	const { setIdVimeo, setModalVimeoIsOpen, setModalGameIsOpen, setGameSelected } = useContext(SectionContext);

  // Open Modal Vimeo
  const openModalVimeo = () => {
    setIdVimeo(idVideo)
    setModalVimeoIsOpen(true);
  }

  // Open Modal Game
  const openModalGame = (game) => {
    setGameSelected(game)
    setModalGameIsOpen(true);
  }

  // Click Event
  const handleClick = (game) => {
    game ? openModalGame(game) : openModalVimeo();
  }

  return (
    <div className='game-card' onClick={() => handleClick(game)}>
      <h3 className='game-card__title'>{title}</h3>
      <img className='game-card__icon' src={process.env.PUBLIC_URL + '/images/icons/hand-down.svg'} alt='Hand Down Icon' />

      {description && <p className='game-card__description'>{description}</p>}

      <div className='game-card__thumbnail-container'>
        <img className='game-card__thumbnail' src={sourceUrl} alt='Thumbnail' />
        <img className='game-card__thumbnail game-card__thumbnail--hover' src={process.env.PUBLIC_URL + '/images/cards/games/background-hover.jpg'} alt='Thumbnail Hover' />
        <p className='game-card__copy-hover title'>Wanna Play?</p>
      </div>

      <div className='game-card__cta-container'>
        <Button className='game-card__cta button--pink' title='Start Now' icon={faPlay} />
      </div>
    </div>
  )
}

export default GameCard;