import React from 'react';
import { SocialMedia } from 'components';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__container container'>
        <div className='footer__left-side'>
          <img className='footer__logo' src={process.env.PUBLIC_URL + '/images/Logo-footer.svg'} alt='GOAL' />
        </div>
        <div className='footer__right-side'>
          <p className='footer__copy'>Follow Us!</p>
          <div className='footer__social-media'>
            <SocialMedia />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;