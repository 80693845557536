import React from 'react'
import { VideosGrid } from 'components';
import useCpt from 'hooks/useCpt';

const PubTvPage = () => {
	const { data, isLoading } = useCpt({
    postType: 'video'
	})
  
  const videos = data?.cptVideos.nodes;

  return (
    <div>
      {!isLoading &&
        <VideosGrid 
          videos={videos}
          videosForPage={9}
          themeColor='orange'
          videoTitleColor='orange'
        />
      }
    </div>
  )
}

export default PubTvPage;