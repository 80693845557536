import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Button = ({ title, iconUrl, className, colorIcon, colorText, onClick, icon = faArrowRight }) => {

	return (
		<button className={className ? `${className} button` : 'button'} onClick={onClick}>
			{title}
			{icon
				&& 
				<FontAwesomeIcon className="button__icon" icon={icon} />
			}
		</button>
	)
}

export default Button;