import { React, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { SectionContext } from 'context/SectionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { SocialMedia } from 'components';
import { Howl } from 'howler';
import pageData from 'data/page-data';
import logoSound from 'assets/sounds/GOAL.mp3';

const Navbar = () => {
	const { showSlider, setShowSlider, menuClicked, setMenuClicked, setSectionDetails, isMute} = useContext(SectionContext);

	const handleClick = () => {
		setShowSlider(!showSlider);
		setSectionDetails(false);
		setMenuClicked(false)
	}

	let navSound = null;
 
	// Menu Sounds
	const getSound = (sound) => {
		if (navSound) {
			navSound.stop();
			navSound.unload();
			navSound = null;
		}

		navSound = new Howl({
			src: [sound],
			volume: 0.5,
			mute: isMute,
			preload: true,
		});
		
		navSound.play();
	}

	// Logo Sound
	const logoHoverSound = new Howl({
		src: [logoSound],
		volume: 0.5,
		mute: isMute,
	});

	return (
		<nav className={`navbar ${menuClicked ? 'active' : ''}`}>
			<a 
				href="/" 
				className="navbar__logo" 
				onMouseEnter={() => logoHoverSound.play()} 
				onMouseLeave={() => logoHoverSound.stop()}
			>
				<img className='navbar__logo-img' src={process.env.PUBLIC_URL + '/images/Logo.svg'} alt='GOAL' />
			</a>

			<div className="navbar__list-container">
				<ul className="navbar__list">
					{
						pageData[0].pages.map(page => {
							return (
								<li className="navbar__item" key={page.id}>
									<NavLink 
										to={page.path} 
										onMouseEnter={() => getSound(page.sound)} 
										onClick={handleClick} 
										className="navbar__link" 
										activeClassName="navbar__link--active"
									>
										{page.title}
										{page.path === '/just-ask' && <FontAwesomeIcon icon={faEnvelope} />}
									</NavLink>
								</li>
							)
						})
					}
				</ul>
				
				{ menuClicked && <SocialMedia /> }
			</div>
			<div 
				className="navbar__hamburger" 
				onClick={() => setMenuClicked(!menuClicked)}
			>
				<FontAwesomeIcon icon={menuClicked ? faTimes : faBars} />
			</div>
		</nav>
	)
}

export default Navbar;