import { React, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { SectionContext } from 'context/SectionContext';
import Pages from 'pages/Pages';
import { Hero, Footer } from 'components';

const MainPage = () => {
	const { sectionDetails } = useContext(SectionContext);

	return(
		<main className="main-page">
			<Hero />

			{/* We show the page content on 'sectionDetails: true' */}
				<CSSTransition
					in={sectionDetails}
					timeout={1000}
					classNames="main-page__content-"
				>
					<div className="main-page__content">
						<div className='main-page__tab container' />
						<Pages />
						<Footer />	
					</div>
				</CSSTransition>
		</main>
	)
}

export default MainPage;