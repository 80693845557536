import React from 'react';
import { Title } from 'components';

const LeftRight = ({title, description, icons, image}) => {
  return (
    <section className='left-right'>
      <div className='left-right__container container'>
        <div className='left-right__left-side'>
          <div className='left-right__title-container'>
            <Title>{title}</Title>
          </div>
          <p className='left-right__description description' dangerouslySetInnerHTML={{__html: description}} />
          <div className='left-right__icons-container'>
          {
            icons.map(({url}, i) => {
              return (
                <div key={i} className='left-right__icon-box'>
                  <img 
                    className='left-right__icon' 
                    src={process.env.PUBLIC_URL + url}
                    alt='Icon' 
                  />  
                </div>
              )         
            })
          }
          </div>
        </div>

        <div className='left-right__right-side'>
          <img className='left-right__img' src={image} alt='Right' />
        </div>
      </div>
    </section>
  )
}

export default LeftRight;
