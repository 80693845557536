import React, { useState, useEffect, useContext } from 'react';
import { Button, Timer, Winner } from 'components';
import { SlickArrowPrev, SlickArrowNext } from 'components/slick/SlickArrow';
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { SectionContext } from 'context/SectionContext';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import Slider from "react-slick"; 

const Puzzle = () => {
  const imgUrls = [
    {
      puzzlePictures: [
        { picture: '/images/games/puzzle-1/Puzzle-1.png', gameId: 1, order: 0 },
        { picture: '/images/games/puzzle-1/Puzzle-2.png', gameId: 1, order: 1 },
        { picture: '/images/games/puzzle-1/Puzzle-3.png', gameId: 1, order: 2 },
        { picture: '/images/games/puzzle-1/Puzzle-4.png', gameId: 1, order: 3 },
        { picture: '/images/games/puzzle-1/Puzzle-5.png', gameId: 1, order: 4 },
        { picture: '/images/games/puzzle-1/Puzzle-6.png', gameId: 1, order: 5 },
        { picture: '/images/games/puzzle-1/Puzzle-7.png', gameId: 1, order: 6 },
        { picture: '/images/games/puzzle-1/Puzzle-8.png', gameId: 1, order: 7 },
        { picture: '/images/games/puzzle-1/Puzzle-9.png', gameId: 1, order: 8 },
        { picture: '/images/games/puzzle-1/Puzzle-10.png', gameId: 1, order: 9 },
        { picture: '/images/games/puzzle-1/Puzzle-11.png', gameId: 1, order: 10 },
        { picture: '/images/games/puzzle-1/Puzzle-12.png', gameId: 1, order: 11 },
        { picture: '/images/games/puzzle-1/Puzzle-13.png', gameId: 1, order: 12 },
        { picture: '/images/games/puzzle-1/Puzzle-14.png', gameId: 1, order: 13 },
        { picture: '/images/games/puzzle-1/Puzzle-15.png', gameId: 1, order: 14 },
        { picture: '/images/games/puzzle-1/Puzzle-16.png', gameId: 1, order: 15 },
        { picture: '/images/games/puzzle-1/Puzzle-17.png', gameId: 1, order: 16 },
        { picture: '/images/games/puzzle-1/Puzzle-18.png', gameId: 1, order: 17 },
        { picture: '/images/games/puzzle-1/Puzzle-19.png', gameId: 1, order: 18 },
        { picture: '/images/games/puzzle-1/Puzzle-20.png', gameId: 1, order: 19 },
      ]
    },
    {
      puzzlePictures: [
        { picture: '/images/games/puzzle-2/Puzzle-1.png', gameId: 2, order: 0 },
        { picture: '/images/games/puzzle-2/Puzzle-2.png', gameId: 2, order: 1 },
        { picture: '/images/games/puzzle-2/Puzzle-3.png', gameId: 2, order: 2 },
        { picture: '/images/games/puzzle-2/Puzzle-4.png', gameId: 2, order: 3 },
        { picture: '/images/games/puzzle-2/Puzzle-5.png', gameId: 2, order: 4 },
        { picture: '/images/games/puzzle-2/Puzzle-6.png', gameId: 2, order: 5 },
        { picture: '/images/games/puzzle-2/Puzzle-7.png', gameId: 2, order: 6 },
        { picture: '/images/games/puzzle-2/Puzzle-8.png', gameId: 2, order: 7 },
        { picture: '/images/games/puzzle-2/Puzzle-9.png', gameId: 2, order: 8 },
        { picture: '/images/games/puzzle-2/Puzzle-10.png', gameId: 2, order: 9 },
        { picture: '/images/games/puzzle-2/Puzzle-11.png', gameId: 2, order: 10 },
        { picture: '/images/games/puzzle-2/Puzzle-12.png', gameId: 2, order: 11 },
        { picture: '/images/games/puzzle-2/Puzzle-13.png', gameId: 2, order: 12 },
        { picture: '/images/games/puzzle-2/Puzzle-14.png', gameId: 2, order: 13 },
        { picture: '/images/games/puzzle-2/Puzzle-15.png', gameId: 2, order: 14 },
        { picture: '/images/games/puzzle-2/Puzzle-16.png', gameId: 2, order: 15 },
        { picture: '/images/games/puzzle-2/Puzzle-17.png', gameId: 2, order: 16 },
        { picture: '/images/games/puzzle-2/Puzzle-18.png', gameId: 2, order: 17 },
        { picture: '/images/games/puzzle-2/Puzzle-19.png', gameId: 2, order: 18 },
        { picture: '/images/games/puzzle-2/Puzzle-20.png', gameId: 2, order: 19 },
      ]
    }
  ]

	const { setTimer, setWinner, winner } = useContext(SectionContext);
  const [imgCollection, setImgCollection] = useState(imgUrls);
  const [blankTile, setBlankTile] = useState(null);
  const blank = Math.floor((Math.random() * 16) + 1);

  useEffect(() => {
    setBlankTile(blank);  
  }, [setBlankTile, blank])

  const moveImage = (index, gameId) => {
    // Get Puzzle Container
    const puzzleContainer = document.querySelector('.slick-active .puzzle__table');

    const id = `img-${gameId}-${index}`;
    const blankTileIndex = Array.from(puzzleContainer.querySelectorAll('.puzzle__img-container')).indexOf(puzzleContainer.querySelector(`#img-${gameId}-${blankTile}`));
    const clickedTileIndex = Array.from(puzzleContainer.querySelectorAll('.puzzle__img-container')).indexOf(puzzleContainer.querySelector(`#img-${gameId}-${index}`));

    if ( (blankTileIndex === clickedTileIndex + 1) && (blankTileIndex %4 !== 0 ) ) {
      puzzleContainer.querySelector(`#${id}`).before(puzzleContainer.querySelector(`#img-${gameId}-${blankTile}`));
    } else if ( (blankTileIndex === clickedTileIndex - 1) && (clickedTileIndex %4 !== 0 ) ) {
      puzzleContainer.querySelector(`#${id}`).after(puzzleContainer.querySelector(`#img-${gameId}-${blankTile}`));
    } else if ( (blankTileIndex + 4 === clickedTileIndex) || (blankTileIndex === clickedTileIndex + 4) ) {
      const prev1 = puzzleContainer.querySelector(`#${id}`).previousElementSibling; 
      const prev2 = puzzleContainer.querySelector(`#img-${gameId}-${blankTile}`).previousElementSibling;

      if ( !prev1 ) {
        puzzleContainer.prepend(puzzleContainer.querySelector(`#img-${gameId}-${blankTile}`));    
      } else {
        prev1.after(puzzleContainer.querySelector(`#img-${gameId}-${blankTile}`));
      }

      if ( !prev2) {
        puzzleContainer.prepend(puzzleContainer.querySelector(`#${id}`));
      } else {
        prev2.after(puzzleContainer.querySelector(`#${id}`));
      }
    }

    isWinner(puzzleContainer)
  }
  
  // If the four elements are in the each corner, then it's iterate all the images and get the attribute 'order'. 
  // Then it validate this new array 'currentOrders' if this array are ordered.
  const isWinner = (puzzleContainer) => {
    const imgTopLeft = puzzleContainer.querySelector('.puzzle__img-container').getAttribute('order');
    const imgTopRight = puzzleContainer.querySelectorAll('.puzzle__img-container')[3].getAttribute('order');
    const imgBottomLeft = puzzleContainer.querySelectorAll('.puzzle__img-container')[16].getAttribute('order');
    const imgBottomRight = puzzleContainer.querySelectorAll('.puzzle__img-container')[19].getAttribute('order');
    
    // If the four elements are in the each corner
    // eslint-disable-next-line eqeqeq
    if (imgTopLeft == 0 && imgTopRight == 3 && imgBottomLeft == 16 && imgBottomRight == 19) {
      const currentImgs = puzzleContainer.querySelectorAll('.puzzle__img-container');
      const currentOrders = Array.from(currentImgs).map((img) => parseInt(img.getAttribute('order')));
  
      if (inAscOrder(currentOrders)) {
        setWinner(true);
      }
    }
  }

  const inAscOrder = (arr) => {
    return arr.every(function(_, i) {
      return i === 0 || arr[i] >= arr[i - 1];
    });
  }

  // Reset Game
  const resetGame = () => {
    shuffle();
    resetTimer();
    setWinner(false);
  }

  // Shuffle
  const shuffle = () => {
    imgCollection.forEach(({puzzlePictures}) => {
      puzzlePictures.sort(()=> Math.random() - 0.5);
    })

    setImgCollection(imgCollection);
  }

  // Reset Timer
  const resetTimer = () => {
    setTimer({
      seconds: 59,
      minutes: 14
    })
  }
  
  const settingsSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SlickArrowPrev />,
    nextArrow: <SlickArrowNext />,
    onSwipe: () => {
      resetTimer();
    },
    afterChange: () => {
      resetTimer();
    }
  };

  return (
    <div className='puzzle'>
      <div className={`puzzle__table-container ${winner ? 'active' : ''}`}>
        <Timer resetGame={resetGame} />

        <Slider {...settingsSlider}>
          {imgCollection.map(({puzzlePictures}, i) => {
            return (
              <div div className='puzzle__table' id={`game-${i}`} key={i}>
                {puzzlePictures.map(({picture, gameId, order}, i) => {
                  return (
                    <div 
                      className={`puzzle__img-container ${i === blank ? 'blank' : ''}`} 
                      key={'image1' + i} 
                      id={`img-${gameId}-${i}`}
                      order={order}
                      onClick={() => i === blank ? null : moveImage(i, gameId)}
                    >
                      {
                        i !== blank && (
                          <img 
                            className='puzzle__img'
                            src={picture} 
                            alt={`Puzzle Img ${i}`} 
                          />
                        )
                      }
                    </div>
                  )
                })}
              </div>
            )
          })}
        </Slider>
      </div>

      {winner && <Winner /> }

      <div className='puzzle__buttons'>
        <Button className='puzzle__button button--pink' title='Restart' icon={faRedoAlt} onClick={resetGame} />
      </div>
    </div>
  )
}

export default Puzzle;