import React, { useContext } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SectionContext } from 'context/SectionContext';
import Lottie from 'react-lottie';
import pageData from 'data/page-data';

const Character = () => {
	const { sectionDetails, pageId } = useContext(SectionContext);
	const { mainImage, lotties, className } = pageData[0].pages[pageId];

  return (
    <>
       {!sectionDetails && <div className={`character character--${className}`}>
        {/* Animates the circle */}
        <div className='character__circles-container'>
          <div className={`character__circle character__circle--primary character__circle--${className}`}></div>
          <div className={`character__circle character__circle--secondary character__circle--${className}`}></div>
          <div className={`character__circle character__circle--tertiary character__circle--${className}`}></div>
        </div>

        {/* Animates the main character */}
        <TransitionGroup>
          <CSSTransition
            key={pageId}
            timeout={{enter: 500, exit: 100}}
            classNames={`character__main-image--${className} character__main-image-`}
            unmountOnExit={true}
          >
            <img className='character__main-image' src={process.env.PUBLIC_URL + mainImage} alt=""/>
          </CSSTransition>
        </TransitionGroup>

        {/* Animates a goup of background icons */}
        {pageId !== 6 && lotties.map(({lottie}, i) => {
          return (
            <TransitionGroup key={i}>
              <CSSTransition
                key={lottie}
                timeout={500}
                classNames={`character__back-icons${i + 1}-`}
                unmountOnExit={true}
              >
                <div className={`character__back-icons${i + 1} character__back-icons${i + 1}--${className}`}>
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: lottie,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice"
                      },
                    }}
                  />
                </div>
              </CSSTransition>
            </TransitionGroup>          
          )
        })}
      </div>}
    </>
  )
}

export default Character;