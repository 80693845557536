import React, { useContext } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import Slider from "react-slick"; 
import { ComicCard } from 'components';
import { SectionContext } from 'context/SectionContext';
import { SlickArrowPrev, SlickArrowNext } from 'components/slick/SlickArrow';

const ComicSlider = ({ comics }) => {
	const { setOpenSliderStack, setItemsSliderStack } = useContext(SectionContext);

  const handleOpenModal = (id) => {
    const currentComic = comics.find(({ databaseId }) => databaseId === id);
    setItemsSliderStack(currentComic);
    setOpenSliderStack(true);
  }

  const comicsQuantity = comics?.length;

  const settingsSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: comicsQuantity > 2 ? 3 : comicsQuantity,
    slidesToScroll: comicsQuantity > 2 ? 3 : comicsQuantity,
    prevArrow: <SlickArrowPrev />,
    nextArrow: <SlickArrowNext />,
    dotsClass: 'slick-dots custom-slick-dots custom-slick-dots--black',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        }
      },
    ]
  };

  return (
    <section className='comic-slider'>
      <div className='comic-slider__container container'>
        <Slider {...settingsSlider}>
          {
            comics && comics.map(( comic ) => {
              return (
                <ComicCard 
                  key={comic.databaseId} 
                  onClick={() => handleOpenModal(comic.databaseId)} 
                  {...comic} 
                />
              )
            })
          }
        </Slider>
      </div>
    </section>
  )
}

export default ComicSlider;