import React, { useContext, useEffect, useState } from 'react';
import { SectionContext } from 'context/SectionContext';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { respondBelow } from 'utilities/breakpoints';

const SliderStack = ({items}) => {
  const { setOpenSliderStack, itemsSliderStack } = useContext(SectionContext);
  const { acf: {gallery} } = itemsSliderStack;
  const pages = gallery;

  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = pages.length - 1;

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  }

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  }

  useEffect(() => {
    currentPage < 0 && setCurrentPage(0)
    currentPage > totalPages && setCurrentPage(totalPages)
  }, [currentPage, totalPages])


  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = ({ targetTouches }) => {
    setTouchStart(targetTouches[0].clientX);
  }

  const handleTouchMove = ({ targetTouches }) => {
    setTouchEnd(targetTouches[0].clientX);
  }

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 150) {
      setCurrentPage(currentPage + 1);
    }

    if (touchStart - touchEnd < -150) {
      setCurrentPage(currentPage - 1);
    }
  }

  const showSlide = currentPage > 3 ? 'slider-stack__slide--display' : '';
  const classes = `slider-stack__slide ${showSlide}`;

  return (
    <TransitionGroup>
      <CSSTransition
        in={true}
        appear={true}
        timeout={10}
        classNames='slider-stack-'
      >

        <div className='slider-stack'>
          <div className='slider-stack__background' onClick={() => setOpenSliderStack(false)} />
          <button 
            className='slider__arrow slider-stack__arrow slider-stack__arrow--left' 
            onClick={handlePrevPage} 
            disabled={currentPage <= 0}
          >❮</button>
          
          {/* Slick Comic */}
          <div className='slider-stack__slides'>
            {
              pages.map(({sourceUrl, databaseId}, i) => {
                return (
                  <div 
                    className={classes}
                    key={databaseId} 
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    onTouchMove={handleTouchMove}
                    style={{
                      zIndex: i + 10,
                      transform: currentPage >= i ? (respondBelow('sm') ? 'translate(0px, 0px)' : `translate(-${i * 10}px, -${i * 10}px )`) : 'translateX(100vw)',
                      opacity: currentPage > i ? `0.15${i}` : 1,
                    }}
                  >
                  {(() => {
                    if (currentPage > i) {
                      return (
                        null
                      )
                      } else {
                        return (
                          <img 
                            className='slider-stack__close' 
                            src={process.env.PUBLIC_URL + '/images/icons/close-modal.svg'} 
                            alt='Close Modal' 
                            onClick={() => setOpenSliderStack(false)}
                          /> 
                        )
                      }
                    })()}  
                    <img 
                      className='slider-stack__slide-img' 
                      src={sourceUrl}
                      alt={`Comic Page ${i}`} 
                    />
                  </div>
                )
              })
            }
          </div>

          {/* Dots */}
          <div className='slider-stack__dots-container'>
              {
                pages.map(({databaseId}, i) => {
                  return (
                    <div 
                      key={databaseId} 
                      className={`slider-stack__dot ${currentPage === i && 'active'}`} 
                      onClick={() => setCurrentPage(i)} 
                    />
                  )
                })
              }
          </div>
          <button 
            className='slider__arrow slider-stack__arrow slider-stack__arrow--right' 
            onClick={handleNextPage} 
            disabled={currentPage >= totalPages}
          >❯</button>
        </div>

      </CSSTransition>
    </TransitionGroup>
  )
}

export default SliderStack;