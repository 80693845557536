import React, { useContext } from 'react'
import { SectionContext } from 'context/SectionContext';
import pageData from 'data/page-data';
import { VideosGrid, Activities, LeftRight } from 'components';
import useCpt from 'hooks/useCpt';

const TeachingPortalPage = () => {
	const { pageId } = useContext(SectionContext);
  const { sections } = pageData[0].pages[pageId];
  const { leftRight, videosGrid, activities, activities2 } = sections;

  // Learning Videos
  const { data: learningVideosData, isLoading: isLoadingLearningVideo } = useCpt({
		postType: 'learningVideo'
	})
  const learningVideos = learningVideosData?.cptLearningVideos.nodes;

  // Learning Modules
  const { data: learningModulesData, isLoading: isLoadingLearningModule } = useCpt({
		postType: 'learningModule'
	})
  const learningModules = learningModulesData?.cptLearningModules.nodes;

  // Games and Activities
  const { data: gamesAndActivitiesData, isLoading: isLoadingGamesAndActivities } = useCpt({
    postType: 'gameAndActivity'
  })
  const gamesAndActivities = gamesAndActivitiesData?.cptGameAndActivitys.nodes;

  return (
    <div>
      <LeftRight 
        title={leftRight.title} 
        description={leftRight.description} 
        icons={leftRight.icons} 
        image={leftRight.image} 
      />
      {!isLoadingLearningVideo &&
        <VideosGrid 
          title={videosGrid.title} 
          description={videosGrid.description} 
          videos={learningVideos}
          videosForPage={6}
          themeColor='green'
          videoTitleColor='black'
          showGridOnMobile={false}
        />
      }
      {!isLoadingLearningModule &&
        <Activities
          title={activities.title}
          allActivities={learningModules}
          description={activities.description}
        />
      }
      {!isLoadingGamesAndActivities &&
        <Activities
          title={activities2.title}
          allActivities={gamesAndActivities}
          description={activities2.description}
        />
      }
    </div>
  )
}

export default TeachingPortalPage;