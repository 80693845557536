import { faFacebookF, faTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import comicTalk from 'assets/lottie/comic-talk';
import punch from 'assets/lottie/punch';
import waveLeft from 'assets/lottie/wave-left-voices';
import waveRight from 'assets/lottie/waves-voices-right';
import music from 'assets/lottie/music';
import hearth from 'assets/lottie/hearth';
import smile from 'assets/lottie/smile';
import talkTeacher from 'assets/lottie/talk-teacher';
import waveTop from 'assets/lottie/wave-top';
import youtube from 'assets/lottie/youtube';
import rayoBig from 'assets/lottie/rayo-big';
import rayoSmall from 'assets/lottie/rayo-small';
import tvWave from 'assets/lottie/tv-wave';
import comicSound from  'assets/sounds/COMICS.mp3';
import voicesSound from  'assets/sounds/VOICES.mp3';
import pubSound from  'assets/sounds/PUBTV.mp3';
import gamesSound from  'assets/sounds/GAMES.mp3';
import justSound from  'assets/sounds/JUSTASK.mp3';
import teachingSound from  'assets/sounds/TEACHINGPORTAL.mp3';
import justInSound from  'assets/sounds/THISJUSTIN.mp3';

const pageData = [
	{
		pages: [
			{
				id: 0,
				path: '/pub-tv',
				className: 'pub-tv',
				title: 'Pub TV',
				ghost_title: 'Pub TV',
				sound: pubSound,
				copy: 'Enjoyable video clips on various topics with friends from our public health family.',
				buttonText: 'Watch Now',
				colors: {
					colorLeft: '#EC7532',
					gradient1: '#FC8B4C',
					gradient2: '#EC7532',
					slider1: '#E16B28',
					navButton: '#5042BA'
				},
				mainImage: '/images/characters/pub-tv.png',
				characterChibi: '/images/characters/pub-tv.png',
				lotties: [
					{
						lottie: rayoBig,
					},
					{
						lottie: rayoSmall,
					},
					{
						lottie: tvWave,
					},
				],
			},
			{
				id: 1,
				path: '/comics',
				className: 'comics',
				title: 'Comics',
				ghost_title: 'Comics',
				sound: comicSound,
				copy: 'Cartoons and adventures of some of your favorite heroes, heroines and champions of public health.',
				buttonText: 'Read Now',
				colors: {
					colorLeft: '#4F4F4F',
					gradient1: '#686868',
					gradient2: '#4F4F4F',
					slider1: '#424242',
					navButton: '#5CD67E'
				},
				mainImage: '/images/characters/comics.svg',
				characterChibi: '/images/characters/comics-chibi.png',
				lotties: [
					{
						lottie: comicTalk,
					},
					{
						lottie: punch
					},
				],
				sections: {
					comics: [
						{
							id: 0,
							title: 'Cloud',
							image: '/images/comics/cloud/Cover.png',
							copy: 'Alien being sent to combat the effects of climate change on Earth.',
							pages: [
								{
									picture: '/images/comics/cloud/page-0.png',
								},
								{
									picture: '/images/comics/cloud/page-1.png',
								},
								{
									picture: '/images/comics/cloud/page-2.png',
								},
							]
						},
						{
							id: 1,
							title: 'Vixen',
							image: '/images/comics/vixen/Cover.png',
							copy: 'Spiritual warrior facing the public health challenges related to bioterrorism.',
							pages: [
								{
									picture: '/images/comics/vixen/0.jpg',
								},
								{
									picture: '/images/comics/vixen/1.jpg',
								},
								{
									picture: '/images/comics/vixen/2.jpg',
								},
								{
									picture: '/images/comics/vixen/3.jpg',
								},
							]
						},
						{
							id: 2,
							title: 'Microbe',
							image: '/images/comics/microbe/Cover.png',
							copy: 'Pint sized hero that packs a punch against germs, viruses, and disease.',
							pages: [
								{
									picture: '/images/comics/microbe/0.jpg',
								},
								{
									picture: '/images/comics/microbe/1.jpg',
								},
								{
									picture: '/images/comics/microbe/2.jpg',
								},
							]
						},
						{
							id: 3,
							title: 'Jefferson High',
							image: '/images/comics/jefferson-high/Cover.png',
							copy: 'The trials of growing up experienced through the eyes of inner-city youth.',
							pages: [
								{
									picture: '/images/comics/jefferson-high/0.jpg',
								},
								{
									picture: '/images/comics/jefferson-high/1.jpg',
								},
								{
									picture: '/images/comics/jefferson-high/2.jpg',
								},
							]
						},
						{
							id: 4,
							title: 'Socially Determined',
							image: '/images/comics/socially-determined/Cover.png',
							copy: 'Jokes, satire and funny takes on different public health topics.',
							pages: [
								{
									picture: '/images/comics/socially-determined/0.jpg',
								},
								{
									picture: '/images/comics/socially-determined/1.jpg',
								},
								{
									picture: '/images/comics/socially-determined/2.jpg',
								},
							]
						},
						{
							id: 5,
							title: 'Viewers Like Me',
							image: '/images/comics/viewers-like-me/Cover.png',
							copy: 'Public service announcements just for kids.',
							pages: [
								{
									picture: '/images/comics/viewers-like-me/0.jpg',
								},
								{
									picture: '/images/comics/viewers-like-me/1.jpg',
								},
								{
									picture: '/images/comics/viewers-like-me/2.jpg',
								},
								{
									picture: '/images/comics/viewers-like-me/3.jpg',
								},
							]
						},
						{
							id: 6,
							title: 'Gods',
							image: '/images/comics/gods/Cover.png',
							copy: 'Supernatural beings from different cultures banned together to save mankind',
							pages: [
								{
									picture: '/images/comics/gods/0.jpg',
								},
								{
									picture: '/images/comics/gods/1.jpg',
								},
								{
									picture: '/images/comics/gods/2.jpg',
								},
								{
									picture: '/images/comics/gods/3.jpg',
								},
							]
						},
						{
							id: 7,
							title: 'The Pollution Squad',
							image: '/images/comics/the-pollution-squad/Cover.png',
							copy: 'A team of specialist joined together in the fight against pollution.',
							pages: [
								{
									picture: '/images/comics/the-pollution-squad/0.jpg',
								},
								{
									picture: '/images/comics/the-pollution-squad/1.jpg',
								},
								{
									picture: '/images/comics/the-pollution-squad/2.jpg',
								},
								{
									picture: '/images/comics/the-pollution-squad/3.jpg',
								},
							]
						},
						{
							id: 8,
							title: 'Defenders of the Earth',
							image: '/images/comics/defenders-of-the-earth/Cover.png',
							copy: 'Environmental health warriors for justice.',
							pages: [
								{
									picture: '/images/comics/defenders-of-the-earth/0.jpg',
								},
								{
									picture: '/images/comics/defenders-of-the-earth/1.jpg',
								},
								{
									picture: '/images/comics/defenders-of-the-earth/2.jpg',
								},
								{
									picture: '/images/comics/defenders-of-the-earth/3.jpg',
								},
								{
									picture: '/images/comics/defenders-of-the-earth/4.jpg',
								},
							]
						},
						{
							id: 9,
							title: 'Viewers Like Me',
							image: '/images/comics/viewers-like-me-two/Cover.png',
							copy: 'Public service announcements just for kids.',
							pages: [
								{
									picture: '/images/comics/viewers-like-me-two/0.jpg',
								},
								{
									picture: '/images/comics/viewers-like-me-two/1.jpg',
								},
								{
									picture: '/images/comics/viewers-like-me-two/2.jpg',
								},
							]
						},
					]	
				}
			},
			{
				id: 2,
				path: '/voices',
				className: 'voices',
				title: 'Voices',
				ghost_title: 'Voices',
				sound: voicesSound,
				copy: '2-minute public health topics explained from the mouths of student thinkers.',
				buttonText: 'Watch Now',
				colors: {
					colorLeft: '#511C97',
					gradient1: '#7A50D1',
					gradient2: '#511C97',
					slider1: '#441088',
					navButton: '#F13764'
				},
				mainImage: '/images/characters/voices.svg',
				characterChibi: '/images/characters/voices-chibi.png',
				lotties: [
					{
						lottie: music
					},
					{
						lottie: waveLeft
					},
					{
						lottie: waveRight
					},
				],
				sections: {
					videosGrid: {
						videos: [
							{
								node: {
									title: 'Air Pollution',
									databaseId: 312,
									acf: {
										idVideo: 'https://vimeo.com/549113761',
										timer: '3:17',
										cover: {
											sourceUrl: '/images/videos/video-placeholder.png',
										},
									}
								}
							},
							{
								node: {
									title: 'Bed Bugs',
									databaseId: 313,
									acf: {
										idVideo: 'https://vimeo.com/549116316',
										timer: '3:10',
										cover: {
											sourceUrl: '/images/videos/video-placeholder-2.png',
										},
									}
								}
							},
							{
								node: {
									title: 'Climate Change & Food Safety Security',
									databaseId: 314,
									acf: {
										idVideo: 'https://vimeo.com/549117546',
										timer: '2:52',
										cover: {
											sourceUrl: '/images/videos/video-placeholder-3.png',
										},
									}
								}
							},
							{
								node: {
									title: 'Endangered Species',
									databaseId: 315,
									acf: {
										idVideo: 'https://vimeo.com/549117806',
										timer: '3:47',
										cover: {
											sourceUrl: '/images/videos/video-placeholder-4.png',
										},
									}
								}
							},
							{
								node: {
									title: 'Environmental Racism',
									databaseId: 316,
									acf: {
										idVideo: 'https://vimeo.com/549120737',
										timer: '2:36',
										cover: {
											sourceUrl: '/images/videos/video-placeholder-5.png',
										},
									}
								}
							},
							{
								node: {
									title: 'Flesh Eating Disease',
									databaseId: 317,
									acf: {
										idVideo: 'https://vimeo.com/549121839',
										timer: '4:34',
										cover: {
											sourceUrl: '/images/videos/video-placeholder-6.png',
										},
									}
								}
							},
							{
								node: {
									title: 'Gang Violence',
									databaseId: 318,
									acf: {
										idVideo: 'https://vimeo.com/549122522',
										timer: '3:25',
										cover: {
											sourceUrl: '/images/videos/video-placeholder-7.png',
										},
									}
								}
							},
							{
								node: {
									title: 'Gene Mutation and the Environment',
									databaseId: 319,
									acf: {
										idVideo: 'https://vimeo.com/549123347',
										timer: '3:01',
										cover: {
											sourceUrl: '/images/videos/video-placeholder-8.png',
										},
									}
								}
							},
							{
								node: {
									title: "Global Warming's Impact on Chicago",
									databaseId: 320,
									acf: {
										idVideo: 'https://vimeo.com/549123961',
										timer: '3:14',
										cover: {
											sourceUrl: '/images/videos/global-warmings-impact-on-chicago.jpg',
										},
									}
								}
							},
							{
								node: {
									title: "Lead in Chicago Water",
									databaseId: 321,
									acf: {
										idVideo: 'https://vimeo.com/549124584',
										timer: '3:08',
										cover: {
											sourceUrl: '/images/videos/lead-in-chicago-water.jpg',
										},
									}
								}
							},
							{
								node: {
									title: 'Noise Pollution',
									databaseId: 322,
									acf: {
										idVideo: 'https://vimeo.com/549126063',
										timer: '3:18',
										cover: {
											sourceUrl: '/images/videos/noise-pollution.jpg',
										},
									}
								}
							},
							{
								node: {
									title: 'Recalling Romaine Lettuce',
									databaseId: 323,
									acf: {
										idVideo: 'https://vimeo.com/549126406',
										timer: '4:35',
										cover: {
											sourceUrl: '/images/videos/recalling-romaine-lettuce.jpg',
										},
									}
								}
							},
							{
								node: {
									title: 'Rodents in Chicago',
									databaseId: 324,
									acf: {
										idVideo: 'https://vimeo.com/549127249',
										timer: '3:18',
										cover: {
											sourceUrl: '/images/videos/rodents-in-chicago.jpg',
										},
									}
								}
							},
							{
								node: {
									title: 'Zebra Mussels',
									databaseId: 325,
									acf: {
										idVideo: 'https://vimeo.com/549127831',
										timer: '5:02',
										cover: {
											sourceUrl: '/images/videos/zebra-mussels.jpg',
										},
									}
								}
							},
						],
					}
				}
			},
			{
				id: 3,
				path: '/games',
				className: 'games',
				title: 'Games',
				ghost_title: 'Games',
				sound: gamesSound,
				copy: 'Fun contests and activities that are centered around public health.',
				buttonText: 'Play Now',
				colors: {
					colorLeft: '#D42A53',
					gradient1: '#F13764',
					gradient2: '#D42A53',
					slider1: '#C21F46',
					navButton: '#5042BA'
				},
				mainImage: '/images/characters/games.svg',
				characterChibi: '/images/characters/games-chibi.png',
				lotties: [
					{
						lottie: smile,
					},
					{
						lottie: hearth
					},
				],
				sections: {
					// gamesGrid: {
					// 	games: [
					// 		{
					// 			thumbnail: '/images/cards/games/thumbnail-1.jpg',
					// 			title: 'Game 1',
					// 			description: 'The Nutri Quiz Game Show',
					// 			idVideo: 'https://vimeo.com/549093331',
					// 		},
					// 		{
					// 			thumbnail: '/images/cards/games/thumbnail-2.jpg',
					// 			title: 'Game 2',
					// 			description: 'The Rambler Fit Quiz Game Show',
					// 			idVideo: 'https://vimeo.com/549097631',
					// 		},
					// 	]
					// },
					steps: {
						title: 'How to Play?',
						allSteps: [
							{
								icon: '/images/icons/playing.svg',
								description: '<strong>Hit the video to start playing</strong> with Larry, in each video there are different games!'
							},
							{
								icon: '/images/icons/messages.svg',
								description: 'When Larry asks you something, just <strong>pause the video, choose an option and play the video again</strong> to find out if you won!'
							},
						]
					},
					interactiveGames: {
						title: 'More Games',
						allGames: [
							{
								databaseId: 1003,
								title: 'Puzzle Master',
								acf: {
									image: {
										sourceUrl: '/images/games/puzzle.png', 
									}
								},
								game: 'puzzle'
							},
							{
								databaseId: 1004,
								title: 'Memory Game',
								acf: {
									image: {
										sourceUrl: '/images/games/memory.png',
									}
								},
								game: 'memory'
							},
							{
								databaseId: 1005,
								title: 'Mosquito Mash',
								acf: {
									image: {
										sourceUrl: '/images/games/save-from-mosquito.png',
									}
								},
								isHtmlGame: true,
								game: 'save-from-mosquito'
							},
							{
								databaseId: 1006,
								title: 'Virus Invasion',
								acf: {
									image: {
										sourceUrl: '/images/games/virus-attack.jpg'
									}
								},
								isHtmlGame: true,
								game: 'virus-attack'
							},
						]
					}
				}
			},
			{
				id: 4,
				path: '/teaching-portal',
				className: 'teaching-portal',
				title: 'Teaching Portal',
				ghost_title: 'Teaching',
				sound: teachingSound,
				copy: 'Learning tools, downloadable content and more for educators, families, and children alike.',
				buttonText: 'Discover Now',
				colors: {
					colorLeft: '#42B462',
					gradient1: '#4DC36E',
					gradient2: '#42B462',
					slider1: '#35A354',
					navButton: '#4754C8'
				},
				mainImage: '/images/characters/teaching.svg',
				characterChibi: '/images/characters/teaching-chibi.png',
				lotties: [
					{
						lottie: talkTeacher
					},
					{
						lottie: waveTop
					},
				],
				sections: {
					leftRight: {
						title: 'About the Portal',
						description: 'Welcome to the teaching portal, an online resource for public health educators. The portal provides downloadable content and pre-recorded lectures that instructors can utilize to engage young learners in various topics related to public health.',
						icons: [
							{
								url: '/images/leftRight/video.svg',
							},
							{
								url: '/images/leftRight/games.svg',
							},
							{
								url: '/images/leftRight/downloadable.svg',
							}
						],
						image: '/images/leftRight/machine.png',
					},
					videosGrid: {
						title: 'Learning Videos',
						description: 'Lectures and lessons from some of the brightest minds and educators on public and environmental health.',
						videos: [
							{
								node: {
									title: 'Air Pollution',
									databaseId: 100,
									acf: {
										idVideo: 'https://vimeo.com/549089031',
										timer: '5:27',
										cover: {
											sourceUrl: '/images/videos/video-placeholder.png',
										}
									}
								}
							},
							{
								node: {
									title: 'Health Equity',
									databaseId: 101,
									acf: {
										idVideo: 'https://vimeo.com/563505734',
										timer: '3:50',
										cover: {
											sourceUrl: '/images/videos/video-placeholder-9.png',
										}
									}
								}
							},
							{
								node: {
									title: 'Statistics Module 1',
									databaseId: 102,
									acf: {
										idVideo: 'https://vimeo.com/575649597',
										timer: '4:08',
										cover: {
											sourceUrl: '/images/videos/cover-statistics.png',
										}
									}
								}
							}
						]
					},
					activities: {
						title: 'Learning Modules',
						description: 'An organized collection of public health content used to support learning goals and objectives.',
						allActivities: [
							{
								title: 'Healthy Eating',
								image: '/images/activities/1-thumbnail.png',
								file: '/pdf/health-eating-module.pdf'
							},
							{
								title: 'Climate Change',
								image: '/images/activities/2-thumbnail.jpg',
								file: '/pdf/climate-change-module.pdf'
							},
							{
								title: 'After The Storm',
								image: '/images/activities/after-the-storm.png',
								file: '/pdf/after-the-storm.pdf'
							},
							{
								title: 'Earth Day Module',
								image: '/images/activities/earth-day-module.png',
								file: '/pdf/earth-day-module.pdf'
							},
							{
								title: 'All About Mosquitoes',
								image: '/images/activities/all-about-mosquitoes.png',
								file: '/pdf/all-about-mosquitoes.pdf'
							},
							{
								title: "Let's Learn About Diseases",
								image: '/images/activities/lets-learn-about-diseases.png',
								file: '/pdf/lets-learn-about-diseases.pdf'
							},
							{
								title: "Illness and Disease",
								image: '/images/activities/illness-and-disease.png',
								file: '/pdf/illness-and-disease.pdf'
							},
							{
								title: "Mental Health For Kids",
								image: '/images/activities/cover-mental-health.png',
								file: '/pdf/mental-health-for-kids.pdf'
							},
							{
								title: "COVID 19 Conversation Tips 4 Kids",
								image: '/images/activities/cover-covid-19.png',
								file: '/pdf/covid-19-kids.pdf'
							},
						]
					},
					activities2: {
						title: 'Games and Activities',
						description: 'Downloadable content for educators, parents, and children to engage in fun, while connecting to public health learning.',
						allActivities: [
							{
								title: 'Maze Craze Food Desert',
								image: '/images/activities/maze-craze-food-desert.png',
								file: '/pdf/maze-craze-food-desert.pdf',
							},
							{
								title: 'Word Search Public Health Vocabulary',
								image: '/images/activities/word-search-public-health-vocabulary.png',
								file: '/pdf/word-search-public-health-vocabulary.pdf'
							},
							{
								title: 'Picture Pages Covid',
								image: '/images/activities/picture-pages-covid.png',
								file: '/pdf/picture-pages-covid.pdf'
							},
							{
								title: 'Connect The Dots',
								image: '/images/activities/connect-the-dots.png',
								file: '/pdf/connect-the-dots.pdf'
							},
							{
								title: "What's Wrong With This Picture",
								image: '/images/activities/whats-wrong-with-this-picture.png',
								file: '/pdf/whats-wrong-with-this-picture.pdf'
							},
							{
								title: 'Maze Craze Fruits & Vegetables',
								image: '/images/activities/maze-craze-fruits-&-vegetables.png',
								file: '/pdf/maze-craze-fruits-&-vegetables.pdf'
							},
							{
								title: 'Connect the Dots 2',
								image: '/images/activities/connect-the-dots-2.png',
								file: '/pdf/connect-the-dots-2.pdf'
							},
							{
								title: 'Picture Pages Exercise',
								image: '/images/activities/picture-pages-exercise.png',
								file: '/pdf/picture-pages-exercise.pdf'
							},
							{
								title: 'Word Search Heart Health Vocabulary',
								image: '/images/activities/word-search-public-health-vocabulary-2.png',
								file: '/pdf/word-search-heart-health-vocabulary.pdf'
							},
							{
								title: 'Maze Craze Technology',
								image: '/images/activities/maze-craze-technology.png',
								file: '/pdf/maze-craze-technology.pdf'
							},
							{
								title: 'Picture Pages Air Pollution',
								image: '/images/activities/pictures-pages-air-pollution.png',
								file: '/pdf/picture-pages-air-pollution.pdf'
							},
							{
								title: 'Connect the Dots 3',
								image: '/images/activities/connect-the-dots-3.png',
								file: '/pdf/connect-the-dots-3.pdf'
							},
							{
								title: 'Word Search Climate Change Vocabulary',
								image: '/images/activities/word-search-climate-change-vocabulary.png',
								file: '/pdf/word-search-climate-change-vocabulary.pdf'
							},
							{
								title: 'Staying Alive (English)',
								image: '/images/activities/cover-staying-alive.png',
								file: '/pdf/staying-alive-english.pdf'
							},
							{
								title: 'Staying Alive (Spanish)',
								image: '/images/activities/cover-staying-alive.png',
								file: '/pdf/staying-alive-spanish.pdf'
							},
						]
					}
				}
			},
			{
				id: 5,
				path: '/just-in',
				className: 'just-in',
				title: 'This Just-In',
				ghost_title: 'This Just-In',
				sound: justInSound,
				copy: 'Spend some time with your favorite YouTube environmental scientist: Dr. Justin Harbison PhD, MPH...',
				buttonText: 'Go to Youtube',
				colors: {
					colorLeft: '#2C39AF',
					gradient1: '#4854C8',
					gradient2: '#2C39AF',
					slider1: '#212EA4',
					navButton: '#5CD67E'
				},
				mainImage: '/images/characters/just-in.png',
				characterChibi: '/images/characters/just-in-chibi.png',
				lotties: [
					{
						lottie: youtube,
					},
				],
			},
			{
				id: 6,
				path: '/just-ask',
				className: 'just-ask',
				title: 'Just Ask',
				sound: justSound,
				ghost_title: 'Just Ask',
				colors: {
					colorLeft: '#DB7B7B',
					gradient1: '#FC8B4C',
					gradient2: '#DB7B7B',
					slider1: '#E16B28',
					navButton: '#5042BA'
				},
				mainImage: '/images/characters/just-ask.png',
				characterChibi: '/images/characters/pub-tv-chibi.png',
			}
		]
	},
	{
		social: [
			{
				id: 0,
				name: 'Tiktok',
				icon: faTiktok,
				url: 'https://www.tiktok.com/@goal4kidz?lang=en'
			},
			{
				id: 1,
				name: 'Instagram',
				icon: faInstagram,
				url: 'https://www.instagram.com/goal4kidz/'
			},
			{
				id: 2,
				name: 'Twitter',
				icon: faTwitter,
				url: 'https://twitter.com/Goal4Kidz'
			},
			{
				id: 3,
				name: 'Facebook',
				icon: faFacebookF,
				url: 'https://www.facebook.com/GOAL-Growth-Oriented-Activist-for-Learning-102975538752396'
			}
		]	
	},
];

export default pageData;