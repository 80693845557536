import React from 'react';
import { useInView } from 'react-intersection-observer';

/*
  lazy: (boolean) - disable lazy load
*/

const Image = ({ src, alt, className = '', lazy = true }) => {
  // Lazy
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    skip: !lazy
  });

  return (
    <img
      ref={ref}
      src={inView ? src : lazy ? null : src}
      alt={alt}
      className={`image ${className}`}
    />
  )
}

export default Image;