import React, { useContext, useEffect, useRef, useState } from 'react';
import { SectionContext } from 'context/SectionContext';
import { preloadSound } from 'utilities/preloadSound';
import { respondBelow, respondAbove } from 'utilities/breakpoints';
import { Button } from 'components';
import gameWin from 'assets/sounds/game-win.mp3';
import pageData from 'data/page-data';
import { Howl } from 'howler';
import ctaSound from 'assets/sounds/buttonclick.mp3';

const IntroVideo = () => {
	const { setVideo, video, isMute } = useContext(SectionContext);
	const [ startNow, setStartNow] = useState(false);
	const videoRef = useRef(null);
	const videoRef2 = useRef(null);

	const ctaHover = new Howl({
		src: [ctaSound],
		volume: 0.3,
		mute: isMute,
	});

	const goStartNow = () => {
		setStartNow(true)
		ctaHover.play();
	}

	// We toggle the video queue to exit when it's finished playing.
	useEffect(() => {	
		// Start Video 2
		if (startNow && !videoRef2.current.ended) {
			videoRef2.current.play();
			videoRef2.current.addEventListener('ended', function() {
				setVideo(!video);
				this.pause();
			})
		} else {
			videoRef2.current.pause();
			videoRef.current.play();
		}
	}, [setVideo, video, startNow]);

	// Preload
	useEffect(() => {
		pageData[0].pages.forEach(page => {
			
			// Preload Hero images
			const imagesToLoad = ['mainImage', 'backgroundIcons1', 'backgroundIcons2'];
			imagesToLoad.forEach(imageToLoad => {
				const img = new Image();
				img.src = page[imageToLoad];
			})

			// Preload Sound
			preloadSound(page.sound);
    });

		// Preload Winner Sound
		preloadSound(gameWin);
	}, []);

	const isIpad = respondBelow('xl') && respondAbove('sm');
	const isMobile = respondBelow('sm');
	
	return (
		<div className='intro-video-container'>
			<video className={`intro-video first ${startNow ? 'fade' : ''}`} autoPlay playsInline muted ref={videoRef}>
				<source src={`${process.env.PUBLIC_URL}/video/${isIpad ? 'intro-ipad-1' : isMobile ? 'intro-mobile-1' : 'intro-desktop-1'}.mp4`} type="video/mp4" />
					Your browser does not support the video tag.
			</video>
			
			<video className={`intro-video second ${startNow ? '' : 'fade'}`} autoPlay playsInline muted ref={videoRef2}>
				<source src={`${process.env.PUBLIC_URL}/video/${isIpad ? 'intro-ipad-2' : isMobile ? 'intro-mobile-2' : 'intro-desktop-2'}.mp4`} type="video/mp4" />
					Your browser does not support the video tag.
			</video>

			<Button 
				className={`intro-video__button button--green ${startNow ? 'fade' : ''}`} 
				title='Start Now'
				onClick={goStartNow}
			/>
		</div>
	)
}

export default IntroVideo;