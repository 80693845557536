import React from 'react';

const DonateButton = () => {
  return (
    <div className='donate-button'>
      <a 
        className='donate-button__link'
        href='https://www.paypal.com/donate?hosted_button_id=5YWZK4SU7936L'
      >
        <div className='donate-button__img'>
          <div className='donate-button__circle donate-button__circle--one' />
          <div className='donate-button__circle donate-button__circle--two' />
          <div className='donate-button__circle donate-button__circle--three' />
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM6.99997 10.5961C6.99997 7.21159 11.426 6.42309 13 9.12409C14.5625 6.44309 19 7.20009 19 10.5961C19 12.6312 16.8762 14.7064 14.1969 17.3243C13.808 17.7043 13.4075 18.0957 13 18.5001C12.5918 18.0949 12.1905 17.7028 11.801 17.3222C9.12296 14.7052 6.99997 12.6307 6.99997 10.5961Z" fill="white"/>
          </svg>
        </div>

        <p className='donate-button__copy'>
          Donate
        </p>
      </a>
    </div>
  )
}

export default DonateButton;
