import React from 'react';
import { ComicSlider } from 'components';
import useCpt from 'hooks/useCpt';

const ComicsPage = () => {
	const { data } = useCpt({
		postType: 'comic'
	})

  const comics = data?.cptComics.nodes;

	return (
		<section className='comics-page'>
			<ComicSlider comics={comics} />
		</section>
	)
}

export default ComicsPage;