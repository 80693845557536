import React, { useContext, useEffect } from 'react';
import gameSound from 'assets/sounds/retro-8.mp3';
import { Howl } from 'howler';
import { SectionContext } from 'context/SectionContext';

const MemoryGameSound = () => {
	const { isMute } = useContext(SectionContext);

  useEffect(() => {
    const sound = new Howl({
      src: [gameSound],
      volume: 0.5,
      mute: isMute,
      loop: true,
    });
    sound.play();

    return () => {
      sound.stop();
    }
  }, [isMute])

  return (
    <>
    </>
  )
}

export default MemoryGameSound;






