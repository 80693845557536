import React from 'react';
import Slider from 'react-slick';
import { Title, StepCard } from 'components';

const Steps = ({title, steps}) => {
  const settingsSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots custom-slick-dots custom-slick-dots--red',
  };
  
  return (
    <section className='steps'>
      <div className='steps__container container'>
        <Title className='steps__title'>{title}</Title>
        <div className='steps__content'>
          {
            steps.map(({description, icon}, i) => {
              return (
                <StepCard
                  key={i}
                  index={i}
                  description={description}
                  icon={icon}
                />
              )
            })
          }
        </div>

        {/* Slider Mobile */}
        <div className='steps__slider'>
          <Slider {...settingsSlider}>
            {
              steps.map(({description, icon}, i) => {
                return (
                  <StepCard
                    key={i}
                    index={i}
                    description={description}
                    icon={icon}
                  />
                )
              })
            }
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default Steps;