import React from 'react';
import { Title } from 'components';

const SectionHeader = ({title, description}) => {
  return (
    <div className='section-header'>
      <Title>{title}</Title>
      <div 
        className='section-header__description description' 
        dangerouslySetInnerHTML={{__html: description}} 
      />
    </div>
  )
}

export default SectionHeader;