import React, { useContext, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getSectionData } from 'utilities/data';
import { SectionContext } from 'context/SectionContext';
import { Navbar, MainPage, ModalGame, SliderStack, ModalVimeo, AlertBar } from 'components';

const MainContainer = ({ routeProps }) => {

	const pathName = routeProps.location.pathname;
	const { setPageId, sectionDetails, openSliderStack, itemsSliderStack } = useContext(SectionContext);

	// Gets the section data from 'src\data\page-data.js'
	const sectionData = getSectionData(pathName);
	const { className, id } = sectionData;

	// Set pageId in context when path name changes
	useEffect(() => {
		const { id } = getSectionData(pathName);
		setPageId(id);
	}, [pathName, setPageId])

	return (
		<div className={`main-container ${className} ${sectionDetails ? '' : 'no-overflow'}`} >
			<Navbar />
			<AlertBar />

			<div className={`main-container__left ${className}__left`}></div>

			{/* Animates the right side background */}
			<TransitionGroup>
				<CSSTransition
					key={id}
					timeout={0}
					classNames="main-container__right-"
				>
					<div className={`main-container__right ${className}__right`}></div>
				</CSSTransition>
			</TransitionGroup>

			{/* Main Page */}
			<MainPage />	

			{/* Modal Vimeo */}
      <ModalVimeo />

			{/* ModalGame */}
      <ModalGame />

			{/* Slick Stack */}
      {openSliderStack && <SliderStack items={itemsSliderStack} />}


		</div>
	)
}

export default MainContainer;