import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

export const usePageViews = () => {
  let location = useLocation();

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize('UA-204896725-1');
      window.GA_INITIALIZED = true;
    }

    ReactGA.set({ page: location.pathname });
    ReactGA.pageview( location.pathname )
  }, [location])
}
