import { usePagination } from 'hooks/usePagination'
import React  from 'react'
import { Pagination, ActivityCard, SectionHeader } from 'components';
import Slider from 'react-slick';

const Activities = ({title, description, allActivities, activitiesForPage = 6}) => {
  // Set Pagination Props
  const {paginatedItems, setPage, page, itemsForPage} = usePagination({
    initPage: 0,
    itemsForPage: activitiesForPage,
    items: allActivities,
  })

  const settingsSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots custom-slick-dots custom-slick-dots--green',
  };

  return (
    <section className='activities'>
      <div className='activities__container container'>

        {/* Header */}
        <SectionHeader
          title={title}
          description={description}
        />

        {/* Grid on Desktop */}
        <div className='activities__grid'>
          {
            paginatedItems.map(({ databaseId, title, acf: {file, image} }) => {
              return (
                <ActivityCard 
                  key={databaseId} 
                  file={file} 
                  title={title}
                  image={image}
                />
              )
            })
          }
        </div>

        {/* Slider on Mobile */}
        <div className='activities__slider'>
          <Slider {...settingsSlider}>
            {
              allActivities && allActivities.map(({ databaseId, title, acf: {file, image} }) => {
                return (
                  <ActivityCard 
                    key={databaseId} 
                    file={file} 
                    title={title}
                    image={image}
                  />
                )
              })
            }
          </Slider>
        </div>


        {/* Pagination */}
        <Pagination
          page={page}
          items={allActivities}
          itemsForPage={itemsForPage}
          setPage={setPage}
          themeColor='green' 
          showOnMobile={false}
        />

      </div>
    </section>
  )
}

export default Activities;
