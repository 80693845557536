import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { MainContainer } from 'components';
import IntroVideo from 'pages/IntroVideo';
import { SectionContext } from 'context/SectionContext';

const Router = () => {
	const { showMainContainer, setShowMainContainer, video } = useContext(SectionContext);

	return (
			<Switch>
				<Route 
					path='/:section'
					render={(routeProps) => { 
						return (
							// Animates the MainContainer when it's first mounted, after the intro-video.
							<CSSTransition
								in={showMainContainer}
								timeout={{ enter: 1000 }}
								classNames="main-container-"
							>
								<MainContainer 
									routeProps={routeProps}
								/>
							</CSSTransition>
						)}
					}
				/>

				<Route exact path="/"
					render={(routeProps) => {
						return (
							<CSSTransition
								in={video}
								timeout={{ exit: 1000 }}
								classNames="video"
								unmountOnExit={true}
								onExiting={() => {
									// Set's the state to mount the main container when video is exiting, 
									setShowMainContainer(!showMainContainer);
									// Redirects to '/pub-tv'
									routeProps.history.push('/pub-tv');
									}
								}
							>
								<IntroVideo routeProps={routeProps} />
							</CSSTransition>
							)
						}
					}
				/>	
			</Switch>
	)
}

export default Router;