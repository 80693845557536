import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { faArrowRight, faEnvelope, faPen, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormMessage } from 'components';

const Form = () => {
  const { register, formState:{ errors, isSubmitted }, handleSubmit, reset } = useForm();
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  
  const onSubmit = (value, e) => {
    if (errors?.name || errors?.email || errors?.message || !value.name || !value.email || !value.message) {
      setFormIsSubmitted(false);
      setSuccess(false);
      setFormIsSubmitted(true);
    } else {
      setFormIsSubmitted(false);
      setSuccess(true);
      setFormIsSubmitted(true);

      const fetch = require('node-fetch');

      const nameSet = value.name;
      const emailSet = value.email;
      const messageSet = value.message;

      const url = 'https://api.sendinblue.com/v3/smtp/email';
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'api-key': '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'
        },
        body: JSON.stringify({
          sender: {name: nameSet, email: 'no-reply@goal.com'},
          to: [{email: 'goal4kidz@gmail.com', name: 'G.O.A.L'}],
          replyTo: {name: nameSet, email: emailSet},
          subject: 'G.O.A.L Website Contact',
          htmlContent: `<html><head></head><body><p><strong>Send by ${nameSet}</strong> <br/></p>${messageSet}</p></body></html>`
        })
      };

      fetch(url, options)
      .then(res => res.json())
      .then(json => console.log(json))
      .catch(err => console.error('error:' + err));

      // Clear Fields
      reset();
    }
  }

  return (
    <form 
      className='form' 
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={`form__input-container ${errors?.name ? 'error' : ''}`}>
        <label htmlFor='name'>Your Full Name</label>
        <div className='form__input-content'>
          <FontAwesomeIcon className='form__icon form__icon--name' icon={faUser} />
          <input 
            id='name'
            className='form__input' 
            type='text'
            {...register('name', {
              required: {
                value: true,
                message: 'Name is required'    
              }
            })}
          />  
        </div>
        {errors?.name && <p>{errors?.name?.message}</p>}
      </div>

      <div className={`form__input-container ${errors?.email ? 'error' : ''}`}>
        <label htmlFor='email'>Contact Email</label>
        <div className='form__input-content'>
          <FontAwesomeIcon className='form__icon form__icon--email' icon={faEnvelope} />
          <input 
            id='email'
            className='form__input' 
            type='email'
            {...register('email', {
              required: {
                value: true,
                message: 'Email is required'    
              },
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'It must be a valid email',
              },
            })}
          />  
        </div>
        {errors?.email && <p>{errors?.email?.message}</p>}
      </div>

      <div className={`form__input-container textarea-container ${errors?.message ? 'error' : ''}`}>
        <label htmlFor='message'>Message</label>
        <div className='form__input-content'>
          <FontAwesomeIcon className='form__icon form__icon--textarea' icon={faPen} />
          <textarea 
            id='message'
            className='form__textarea' 
            {...register('message', {
              required: {
                value: true,
                message: 'Message is required'    
              },
            })}
          />  
        </div>
        {errors?.message && <p>{errors?.message?.message}</p>}
      </div>

      <div className='form__disclaimer-container'>
        <p className='form__disclaimer'><span>*</span>If under 18 please check with your parents before sending.</p>
      </div>

      <div className='form__submit-container'>
        {isSubmitted && formIsSubmitted && <FormMessage success={success} />}
        <button className='form__submit' onClick={onSubmit}>Send Message <FontAwesomeIcon className='form__submit-icon' icon={faArrowRight} /></button>
      </div>
    </form>
  )
}

export default Form;
