import { SectionContext } from 'context/SectionContext';
import React, { useContext } from 'react'
import ComicsPage from './ComicsPage';
import GamesPage from './GamesPage';
import PubTvPage from './PubTvPage';
import TeachingPortalPage from './TeachingPortalPage';
import VoicesPage from './VoicesPage';

const Pages = () => {
	const { pageId } = useContext(SectionContext);
  
  switch(pageId) {
    case 0:
      return <PubTvPage />

    case 1:
      return <ComicsPage />
      
    case 2:
      return <VoicesPage />

    case 3:
      return <GamesPage />
      
    case 4:
      return <TeachingPortalPage />

    default:
      return <></>
  }
}

export default Pages;
