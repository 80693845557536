import { useState } from 'react';

export const usePagination = ({initPage = 0, itemsForPage = 9, items}) => {
  const [page, setPage] = useState(initPage);
  const paginatedItems = items.slice(itemsForPage * page, itemsForPage * (page + 1));

  return {
    paginatedItems,
    page,
    setPage,
    itemsForPage,
  }
}
