import React from 'react';
import { Image } from 'components';

const ComicCard = ({title, acf: {image, description}, onClick}) => {
	return (
		<div className='comic-card' onClick={onClick}>
			<div className='comic-card__img-container'>
				<img className='comic-card__icon' src={process.env.PUBLIC_URL + '/images/icons/comic.svg'} alt="Icon"/>
				<img className='comic-card__play-icon' src={process.env.PUBLIC_URL + '/images/icons/play-gray.svg'} alt="Play Icon"/>
				<Image
					className='comic-card__image'
					src={image.sourceUrl}
					alt={image.altText ? image.altText : title} 
				/>
				<div className='comic-card__bottom comic-card__bottom--primary' />
				<div className='comic-card__bottom comic-card__bottom--secondary' />
				<div className='comic-card__bottom comic-card__bottom--tertiary' />
			</div>
			<div className='comic-card__text-container'>
				<h3 className='comic-card__title'>{title}</h3>
				<p className='comic-card__copy'>{description}</p>
			</div>
		</div>
	)
}

export default ComicCard;