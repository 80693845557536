import React from 'react';
import { Puzzle, MemoryGame } from 'components';

export const GameSelect = ({ gameSelected, setWinner, winner }) => {
  switch(gameSelected) {
    case 'puzzle':
      return <Puzzle />
    case 'memory':
      return <MemoryGame setWinner={setWinner} winner={winner} />
    case 'save-from-mosquito':
      return <iframe title='Save From Mosquito' src={process.env.PUBLIC_URL + '/games/save-from-mosquito/index.html'} />
    case 'virus-attack':
      return <iframe title='Virus Attack' src={process.env.PUBLIC_URL + '/games/virus-attack/index.html'} />
    default:
      return <></>
  }
}

export default GameSelect;