import { gql } from "graphql-request";

export const queryImage = `
  image {
    databaseId
    sourceUrl
    srcSet(size: MEDIUM)
    altText
  }
`;

export const queryGallery = `
  gallery {
    databaseId
    sourceUrl
    srcSet(size: MEDIUM)
    altText
  }
`;

export const queryCpt = `
  title
  databaseId
`;


// Queries CPT ACF
export const queryCptAcf = {
  video: `
    acf {
      cover {
        sourceUrl(size: MEDIUM)
        altText
      }
      idVideo
      timer
    }
  `,
  voice: `
    acf {
      cover {
        sourceUrl(size: MEDIUM)
        altText
      }
      idVideo
      timer
    }
  `,
  comic: `
    acf {
      ${queryImage}
      description
      ${queryGallery}
    }
  `,
  learningVideo: `
    acf {
      cover {
        sourceUrl(size: MEDIUM)
        altText
      }
      idVideo
      timer
    }
  `,
  learningModule: `
    acf {
      ${queryImage}
      file {
        guid
      }
    }
  `,
  gameAndActivity: `
    acf {
      ${queryImage}
      file {
        guid
      }
    }
  `,
  gameShow: `
    acf {
      ${queryImage}
      vimeoUrl
      description
    }
  `,
  activity: `
    acf {
      ${queryImage}
      file {
        guid
      }
    }
  `
}

export const GET_VIDEOS = gql`
  query getVideos {
    cptVideos(last: 1000, where: {orderby: {field: DATE, order: DESC}, status: PUBLISH}) {
      edges {
        node {
          title
          databaseId
          acf {
            cover {
              sourceUrl(size: MEDIUM)
              altText
            }
            idVideo
            timer
          }
        }
      }
    }
  }
`;

// PAGES
export const GET_RELATIONSHIP = (postType) => { 
  return `
    select
    relationship {
      ... on Cpt${postType} {
        ${queryCpt}
        ${queryCptAcf[postType.toLowerCase()]}
      }
    }
  `
};

// export const GET_PAGE = gql`
//   page(id: "teaching-portal", idType: URI) {
//     ${queryPageAcf[pageName]}
//   }
// `;

// Queries PAGE ACF
export const queryPageAcf = {
  teachingPortal: `
    acfTeachingPortal {
      learningModules {
        ${GET_RELATIONSHIP('Activity')}
      }
      gamesAndActivities {
        ${GET_RELATIONSHIP('Activity')}
      }
    }
  `
}