import React, {useContext} from 'react';
import { SectionContext } from 'context/SectionContext';

const Mute = () => {
  const {isMute, setIsMute} = useContext(SectionContext);
  const showImage = isMute ? '/images/icons/mute.svg' : '/images/icons/unmute.svg';

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="mute" onClick={() => setIsMute(!isMute)}>
      <img src={process.env.PUBLIC_URL +  showImage} alt="mute" />
    </a>
  )
}

export default Mute;