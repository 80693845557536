import React from 'react'
import { VideosGrid } from 'components';
import useCpt from 'hooks/useCpt';

const VoicesPage = () => {
  const { data, isLoading } = useCpt({
		postType: 'voice'
	})

  const videos = data?.cptVoices.nodes;

  return (
    <section className='voices-page'>
      {!isLoading &&
        <VideosGrid 
          videos={videos}
          showBubbles={true}
        />
      }
    </section>
  )
}

export default VoicesPage;