import React, { useContext, useEffect } from 'react';
import { SectionContext } from 'context/SectionContext';
import { Title } from 'components';
import { Howl } from 'howler';
import gameWin from 'assets/sounds/game-win.mp3';
import winnerLottie from 'assets/lottie/winner.json';
import Lottie from 'react-lottie';
import Confetti from 'react-confetti';

const Winner = () => {
	const { winner, isMute } = useContext(SectionContext);

  useEffect(() => {
    const sound = new Howl({
      src: [gameWin],
      volume: 0.5,
      mute: isMute,
    });
    
    sound.play();
  }, [isMute])

  return (
    <>
      <Confetti 
        className='winner__confetti'
        width={window.innerWidth}
        height={window.innerHeight}
        numberOfPieces={40}
      />

      <div className={`winner ${winner ? 'active' : ''}`}>
        <div className='winner__img-container'>
          <div className='winner__img'>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: winnerLottie,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                },
              }}
            />
          </div>
          <div className='winner__circle winner__circle--big' />
          <div className='winner__circle winner__circle--small' />
        </div>
        <Title className='winner__title'>Well Done!</Title>
      </div>
    </>
  )
}

export default Winner;