import React, { useContext } from 'react';
import pageData from 'data/page-data';
import { SectionContext } from 'context/SectionContext';
import { GamesGrid, Steps } from 'components';
import useCpt from 'hooks/useCpt';

const GamesPage = () => {
  const { pageId } = useContext(SectionContext);
  const { sections } = pageData[0].pages[pageId];
  const { steps, interactiveGames } = sections;

	// Games Show
	const { data: gamesShowData, isLoading: isLoadingGamesShow } = useCpt({
    postType: 'gameShow'
  })
  const gamesShow = gamesShowData?.cptGameShows.nodes;

	return (
		<div className='games-page'>
			{!isLoadingGamesShow &&
				<GamesGrid 
					games={gamesShow} 
				/>
			}
			<Steps title={steps.title} steps={steps.allSteps} />
			<GamesGrid title={interactiveGames.title} games={interactiveGames.allGames} />
		</div>
	)
}

export default GamesPage;