import { SectionContext } from 'context/SectionContext';
import React, { useContext } from 'react';
import ModalVideo from 'react-modal-video';

const ModalVimeo = () => {
	const { modalVimeoIsOpen, setModalVimeoIsOpen, idVimeo } = useContext(SectionContext);

  const getVimeoVideoId = (url) => {
    var regex = new RegExp(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
    if ( regex.test(url) ) {
      return regex.exec(url)[5];
    }
  }

  const getIdVimeo = getVimeoVideoId(idVimeo);
  
  return (
    <div>
        { /* Modal Vimeo */
          idVimeo && (
            <ModalVideo 
              channel='vimeo' 
              autoplay 
              isOpen={modalVimeoIsOpen} 
              videoId={getIdVimeo}
              onClose={() => setModalVimeoIsOpen(false)} 
            />
          )
        }
    </div>
  )
}

export default ModalVimeo;